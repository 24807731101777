<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      justify="center"
    >
      <v-col cols="auto">
        <v-btn
          color="primary"
          to="/main/eta/create"
          v-if="userAccess.canCreate"
        >
          {{$_strings.common.ADD}}
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        class="ml-md-auto"
      >
        <v-text-field
          dense
          hide-details
          class="caption"
          outlined
          @keyup.native.enter="searchEta"
          v-model="search"
          :placeholder="$_strings.eta.searchTextAsal"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="searchEta">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="3"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          @keyup.native.enter="searchEta"
          v-model="search2"
          :placeholder="$_strings.eta.searchTextTujuan"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="searchEta">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-data-table
      :headers="displayedHeader"
      :items="items"
      item-key="id"
      :server-items-length="totalItems"
      :options.sync="pagination"
      @click:row="rowClicked"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.originLocationName`]=items>
        <span>{{ items.item.originLocationName }}</span>
      </template>
      <template v-slot:[`item.destinationLocationName`]=items>
        <span>{{ items.item.destinationLocationName }}</span>
      </template>
      <template v-slot:[`item.duration`]=items>
        <span>{{ items.item.duration }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon x-small class="mr-2" color="primary" @click="rowClicked(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Ubah Data</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.eta.pageName}}
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'component-dokument',
  components: {},
  data() {
    return {
      items: [],
      search: this.$route.query.name || '',
      search2: this.$route.query.nameDestination || '',
      filter: '',
      headers: [
        {
          text: 'Lokasi Asal',
          value: 'originLocationName',
        },
        {
          text: 'Lokasi Tujuan',
          value: 'destinationLocationName',
        },
        {
          text: 'Estimasi Tiba (Hari)',
          value: 'duration',
        },
        {
          text: 'Aksi',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        itemsPerPage: Number(this.$route.query.itemsPerPage) || 10,
        page: Number(this.$route.query.page) || 1,
        sortBy: [],
        sortDesc: [],
      },
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        const { page, itemsPerPage } = this.$route.query;
        if ((Number(newVal.page) !== Number(page)) || (Number(newVal.itemsPerPage) !== Number(itemsPerPage))) {
          this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              page: newVal.page,
              itemsPerPage: newVal.itemsPerPage,
            },
          });
        }
        this.fetchEta();
      },
      deep: true,
    },
  },
  methods: {
    rowClicked(e, { item }) {
      this.$router.push(`/main/eta/edit/${item.id}`);
    },
    searchEta() {
      const { name, nameDestination } = this.$route.query;
      if (name !== this.search || nameDestination !== this.search2) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            name: this.search,
            nameDestination: this.search2,
          },
        });
      }
      this.fetchEta(1, this.search, this.search2);
    },
    fetchEta(page, name = this.$route.query.name || '', nameDestination = this.$route.query.nameDestination || '') {
      this.$root.$loading.show();
      const _page = (page || this.pagination.page) - 1;
      const size = this.pagination.itemsPerPage;
      const { sortBy, sortDesc } = this.pagination;
      let sort = '';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        // sort = `${sortBy[0].includes('originLocationName') ? 'id' : sortBy[0]},${isDesc}`;
        sort = `${sortBy[0]},${isDesc}`;
      }
      this.$_services.eta.etaList({
        page: _page, size, name, nameDestination, sort,
      })
        .then((res) => {
          this.items = res.data.contents;
          this.totalItems = res.data.totalData;
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          if (page) this.pagination.page = page;
          this.$root.$loading.hide();
        });
    },
  },
  created() {
    // this.getRoleList();
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
